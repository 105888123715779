import { initializeApp } from 'firebase/app'
import { getAuth, signInWithRedirect, signInWithPopup, SAMLAuthProvider } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import { initializeFirestore } from 'firebase/firestore'

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  application: process.env.VUE_APP_APP_NAME
}

export const firebaseApp = initializeApp(config)
export const db = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true
})
export const analytics = getAnalytics(firebaseApp)

const auth = getAuth(firebaseApp)
const providerSAML = new SAMLAuthProvider('saml.pass')

export let globalProperties = {}

auth.onAuthStateChanged(async firebaseUser => {
  if (firebaseUser) {
    // User is signed in
    const token = await firebaseUser.getIdToken(true)
    const user = await authorize(token, config.application)
    const result = await firebaseUser.getIdTokenResult(true)
    // Perform any necessary actions when the user is signed in
    globalProperties = { firebaseUser, user, roles: result.claims.roles }
    import(/* webpackChunkName: "createApp" */ './createApp.js').then(createApp => {
      createApp.createApp({ user, roles: result.claims.roles })
    })
  } else {
    return process.env.NODE_ENV === 'localhost' ? signInWithPopup(auth, providerSAML) : signInWithRedirect(auth, providerSAML)
  }
})

export const authorize = async (idToken, application) => {
  if (!idToken || !application) throw new Error('IdToken or Application must be specified')

  let headers = new Headers({
    Authorization: `Bearer ${idToken}`,
    application
  })
  const response = await fetch(process.env.VUE_APP_AUTHORIZE_ENDPOINT, {
    headers,
    method: 'GET',
    cache: 'default',
    mode: 'cors'
  })
  if (!response.ok) {
    return response.json().then(message => {
      throw message
    })
  }
  return response.json()
}

export const logout = async () => {
  try {
    await auth.signOut()
    window.location.href = process.env.VUE_APP_LOG_OUT_LOCATION
  } catch (error) {
    console.error('Error signing out:', error)
  }
}
